import React from 'react';
import { Helmet } from 'react-helmet';

export default function Meta({ title }) {
  return (
    <Helmet>
      <meta property="og:title" content={title} />
      <meta property="og:type" content="Website" />
      <meta property="og:url" content="https://bootsandlegs.com/" />
      <meta property="og:description" content="These boots were made for dancin'" />
      <meta property="og:image" content="/images/og-image.png" />
      <link rel="icon" type="image/png" href="/images/fav.png" />
      <title>{title}</title>
      <meta name="description" content="These boots were made for dancin'" />
    </Helmet>
  );
}
