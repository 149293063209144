import React from 'react';
import * as style from './graphic.module.scss';

export default function InfoGraphic({ color }) {
  return (
    <div className={[style.container, style.info].join(' ')}>
      <svg className={style.graphic} viewBox="0 0 190 212" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g>
          <path
            d="M18.72 81.68L154 1.94C154 1.94 177.28 -8.74 171 19.94C164.72 48.62 147.44 157.14 147.44 157.14L82.44 150.97L128.58 47.57C128.58 47.57 54.8 146 0 143.17"
            fill={color}
          />
          <path
            d="M116.18 141.3L137.08 143.3C137.08 143.3 122.16 177 141.35 184.7C160.54 192.4 186.64 189 186.64 189C186.64 189 195.11 210.89 184.55 211.06C173.99 211.23 120.68 205 120.68 205L120.84 191L100.84 203.69L83.4702 202L116.18 141.3Z"
            fill={color}
          />
          <path
            d="M112.73 153.87L128.12 155.33C128.12 155.33 124.45 176.15 118.41 175.85C112.37 175.55 106.58 178.52 112.73 153.87Z"
            fill="white"
          />
        </g>
      </svg>
      <h3 className={style.infoText} style={{ color }}>
        Info
      </h3>
    </div>
  );
}
