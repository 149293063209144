import React from 'react';
import * as style from './Hamburger.module.scss';

export default function Hamburger({ justCross = false, isOpen, handleClick, bgColor = 'black' }) {
  return (
    <div className={[style.container, justCross ? style.justCross : null].join(' ')}>
      <button type="button" onClick={handleClick} className={[style.button, isOpen ? style.open : null].join(' ')}>
        <div className={style.lines}>
          <div className={[style.line, style.one].join(' ')} style={{ background: bgColor }} />
          <div className={[style.line, style.two].join(' ')} style={{ background: bgColor }} />
          <div className={[style.line, style.three].join(' ')} style={{ background: bgColor }} />
        </div>
      </button>
    </div>
  );
}
