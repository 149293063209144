import React from 'react';
import * as style from './PageContent.module.scss';

export default function PageContent({ children, viewHeight }) {
  return (
    <div
      className={style.pageWrap}
      style={{
        minHeight: `${viewHeight}px`,
      }}
    >
      {children}
    </div>
  );
}
