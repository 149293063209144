import React, { useState } from 'react';
import { Link } from 'gatsby';
import { red } from '../../consts/colors';
import BuyGraphic from './graphics/BuyGraphic';
import StreamGraphic from './graphics/StreamGraphic';
import InfoGraphic from './graphics/InfoGraphic';

import * as style from './NavItem.module.scss';

export default function NavItem({ to, hoverColor, changeHoverColor, closeMenu }) {
  const [color, setColor] = useState(red);

  const handleMouseEnter = () => {
    setColor(hoverColor);
  };

  const handleMouseLeave = () => {
    setColor(red);
    changeHoverColor();
  };

  if (to === 'info')
    return (
      <Link
        to="/info"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className={style.navItem}
        onClick={closeMenu}
      >
        {to === 'stream' && <StreamGraphic color={color} />}
        {to === 'buy' && <BuyGraphic color={color} />}
        {to === 'info' && <InfoGraphic color={color} />}
      </Link>
    );

  return (
    <a
      href={to === 'stream' ? 'https://soundcloud.com/bootsandlegsoffical' : 'https://bootsandlegs.bandcamp.com'}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={style.navItem}
      target={to === 'info' ? null : '_blank'}
      rel="noreferrer noopener"
    >
      {to === 'stream' && <StreamGraphic color={color} />}
      {to === 'buy' && <BuyGraphic color={color} />}
      {to === 'info' && <InfoGraphic color={color} />}
    </a>
  );
}
