import React from 'react';
import NavItem from '../NavItem/NavItem';
import * as style from './DesktopNav.module.scss';

export default function DesktopNav({ hideInfo, changeHoverColor, hoverColor }) {
  return (
    <nav className={[style.nav, hideInfo ? style.infoNav : null].join(' ')}>
      <NavItem to="stream" changeHoverColor={changeHoverColor} hoverColor={hoverColor} />
      <NavItem to="buy" changeHoverColor={changeHoverColor} hoverColor={hoverColor} />
      {!hideInfo && <NavItem to="info" changeHoverColor={changeHoverColor} hoverColor={hoverColor} />}
    </nav>
  );
}
