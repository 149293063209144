import React from 'react';
import NavItem from '../NavItem/NavItem';
import * as style from './MobileMenu.module.scss';

export default function MobileMenu({ isOpen, height, changeHoverColor, hoverColor, closeMenu }) {
  return (
    <div className={[style.container, isOpen ? style.visible : null].join(' ')} style={{ height }}>
      <div className={style.items}>
        <NavItem to="stream" changeHoverColor={changeHoverColor} hoverColor={hoverColor} closeMenu={closeMenu} />
        <NavItem to="buy" changeHoverColor={changeHoverColor} hoverColor={hoverColor} />
        <NavItem to="info" changeHoverColor={changeHoverColor} hoverColor={hoverColor} closeMenu={closeMenu} />
      </div>
    </div>
  );
}
