import React from 'react';
import { motion } from 'framer-motion';

export default function Layout({ children }) {
  return (
    <>
      <motion.div
        initial={{ opacity: 0, scale: 0.9, overflow: 'hidden' }}
        animate={{ opacity: 1, scale: 1, overflow: 'auto' }}
        exit={{ opacity: 0, scale: 1.2, overflow: 'hidden' }}
        transition={{
          type: 'spring',
          mass: 0.55,
          stiffness: 85,
        }}
      >
        {children}
      </motion.div>
    </>
  );
}
