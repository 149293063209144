import React from 'react';
import * as style from './graphic.module.scss';

export default function BuyGraphic({ color }) {
  return (
    <div className={[style.container, style.buy].join(' ')}>
      <svg className={style.graphic} viewBox="0 0 415 293" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g>
          <path
            d="M189.515 223.73C246.127 186.663 262.082 110.89 225.151 54.4863C188.22 -1.91704 112.389 -17.5919 55.7767 19.4755C-0.835372 56.543 -16.7902 132.316 20.1405 188.719C57.0712 245.123 132.903 260.797 189.515 223.73Z"
            fill={color}
          />
          <path
            d="M145.201 157.45C164.411 144.917 169.496 118.683 156.56 98.8531C143.623 79.0234 117.563 73.1077 98.3533 85.64C79.1435 98.1723 74.058 124.407 86.9947 144.237C99.9314 164.066 125.991 169.982 145.201 157.45Z"
            fill="white"
          />
          <path
            d="M127.121 129.736C131.501 126.878 132.66 120.894 129.708 116.371C126.757 111.847 120.814 110.496 116.433 113.354C112.053 116.212 110.894 122.195 113.846 126.719C116.797 131.243 122.74 132.594 127.121 129.736Z"
            fill={color}
          />
          <path
            d="M11.8498 132.88C9.99976 101 22.8298 68.36 47.9998 45"
            stroke="white"
            strokeWidth="1.6"
            strokeMiterlimit="10"
          />
          <path
            d="M91.21 19.48C128.79 7.09001 169.71 15 198 40.24"
            stroke="white"
            strokeWidth="1.6"
            strokeMiterlimit="10"
          />
          <path
            d="M26.5299 151.51C16.2199 115.24 29.0499 74.51 60.3899 48.74"
            stroke="white"
            strokeWidth="1.43"
            strokeMiterlimit="10"
          />
          <path
            d="M119.77 25.05C142.765 23.3979 165.593 30.0156 184.14 43.71"
            stroke="white"
            strokeWidth="1.43"
            strokeMiterlimit="10"
          />
          <path
            d="M44.2496 159.22C29.1396 127.16 37.4896 88.16 64.3796 62.88"
            stroke="white"
            strokeWidth="1.23"
            strokeMiterlimit="10"
          />
          <path
            d="M108 40.12C136.82 34.12 166.81 42.96 186.36 64.42"
            stroke="white"
            strokeWidth="1.23"
            strokeMiterlimit="10"
          />
          <path
            d="M407.226 207.749C427.998 143.896 392.882 75.2316 328.793 54.383C264.703 33.5343 195.91 68.3963 175.138 132.249C154.366 196.103 189.482 264.767 253.572 285.616C317.661 306.464 386.455 271.602 407.226 207.749Z"
            stroke={color}
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M330.738 182.632C338.062 160.117 326.318 136.113 304.507 129.017C282.695 121.922 259.076 134.422 251.752 156.937C244.428 179.453 256.172 203.457 277.983 210.552C299.794 217.647 323.414 205.147 330.738 182.632Z"
            fill={color}
          />
          <path
            d="M300.25 172.714C301.921 167.578 299.244 162.102 294.27 160.484C289.297 158.866 283.91 161.719 282.239 166.855C280.568 171.992 283.246 177.467 288.219 179.085C293.193 180.703 298.579 177.851 300.25 172.714Z"
            fill="white"
          />
          <path
            d="M213.47 91.31C237.17 69.83 270.61 59.39 304.6 64.36"
            stroke={color}
            strokeWidth="1.6"
            strokeMiterlimit="10"
          />
          <path
            d="M351.57 82C384.79 103.49 404.32 140.31 402.39 178.19"
            stroke={color}
            strokeWidth="1.6"
            strokeMiterlimit="10"
          />
          <path
            d="M208.18 114.43C230 83.64 269.75 68.07 309.45 76.33"
            stroke={color}
            strokeWidth="1.43"
            strokeMiterlimit="10"
          />
          <path
            d="M365.15 107.72C380.863 124.588 390.031 146.517 391 169.55"
            stroke={color}
            strokeWidth="1.43"
            strokeMiterlimit="10"
          />
          <path
            d="M213.28 133.06C228.78 101.19 264.39 83.22 300.94 88.31"
            stroke={color}
            strokeWidth="1.23"
            strokeMiterlimit="10"
          />
          <path
            d="M346 108C368.73 126.7 380.68 155.58 376.23 184.27"
            stroke={color}
            strokeWidth="1.23"
            strokeMiterlimit="10"
          />
        </g>
      </svg>
      <h3 className={style.buyText} style={{ color }}>
        Buy
      </h3>
    </div>
  );
}
