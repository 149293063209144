import React from 'react';
import * as style from './graphic.module.scss';

export default function StreamGraphic({ color }) {
  return (
    <div className={style.container}>
      <svg className={style.graphic} viewBox="0 0 390 280" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g>
          <path
            d="M1.31982 187.78L116.78 133.14L156.83 230.95L121.96 244.64L101.8 194.55L99.7698 195.58L118.83 244.84L74.2498 264.49L59.3698 231.14L57.6398 232.3L72.0098 264.64L42.3798 277.98L1.31982 187.78Z"
            stroke={color}
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M180.51 156.53L198.92 214.09L159.66 229.85L121.81 130.58L304.19 41.22L336.15 158.59L288.57 177.45L276.35 109.59L273.13 110.45L285.73 179.1L247.63 193.98L234.37 135.38L231.04 137.03L244.96 195.79L202.41 212.75L184.34 155.4L180.51 156.53Z"
            stroke={color}
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M307.43 39.36L385.19 1.57996L388.08 137.54L373.02 143.57L364.57 86.83L361.7 87.82L369.94 145.37L339.28 157.47L307.43 39.36Z"
            stroke={color}
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path d="M92.6899 206.25L118.78 194.16L98.1199 141.97L69.9399 155.3L92.6899 206.25Z" fill={color} />
          <path d="M73.2796 231.7L45.9596 243.38L16.3496 180.66L49.5196 164.97L73.2796 231.7Z" fill={color} />
          <path d="M170.63 166.53L198.21 160.94L185.98 99.1699L153.67 115L170.63 166.53Z" fill={color} />
          <path d="M217.24 149.39L250.22 137.18L243.57 71.0599L206.21 89.0199L217.24 149.39Z" fill={color} />
          <path d="M264.08 119.42L291.13 112.17L287.1 49.59L255.8 64.92L264.08 119.42Z" fill={color} />
          <path d="M349.66 96.87L375.17 87.5L370.1 8.88L338.86 24.07L349.66 96.87Z" fill={color} />
        </g>
      </svg>
      <h3 className={style.streamText} style={{ color }}>
        Stream
      </h3>
    </div>
  );
}
