import React from 'react';
import * as style from './Footer.module.scss';

export default function Footer() {
  const year = new Date().getFullYear().toString();

  return (
    <footer className={style.footer}>
      <span className={style.copyright}>©</span> Boots &amp; Legs {year}
    </footer>
  );
}
